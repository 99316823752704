<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="sm-stop-edit" v-if="loading"></div>
        <div class="modal-card card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t('Add expense') }}
            </h4>
            <button type="button" class="close" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-sm-12">
                    {{ $t('The amount') }}
                  </label>
                  <div class="add-input col-md-12">
                    <input
                      type="number"
                      class="form-control"
                      v-model="expense.cost"
                      dir="rtl"
                      :class="{ 'is-invalid': errors.cost }"
                      @change="errors.cost = false"
                    />
                    <span>{{ $option.currency }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-sm-12">{{ $t('section') }}</label>
                  <div class="col-md-12">
                    <select class="form-control" v-model="expense.expense_section_id">
                      <option value="0">
                        {{ $t('Choose a rating') }}
                      </option>
                      <option
                        v-for="(expenseSection, index) in filletedExpensesSections"
                        :value="expenseSection.id"
                        :key="index"
                      >
                        {{ expenseSection.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-12">{{ $t('That is about') }} </label>
                  <div class="col-md-12">
                    <textarea class="form-control" v-model="expense.description"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="$emit('close')">
                  <i class="far fa-times"></i>
                  {{ $t('Close') }}
                </button>
                <button @click="createExpense()" class="btn btn-dark" v-if="!loading">
                  <i class="far fa-save"></i>
                  {{ $t('Save') }}
                </button>
                <button class="btn btn-dark" v-if="loading">
                  {{ $t('Loading') }}
                  <span class="loading-s1 sm-j">.</span><span class="loading-s2 sm-j">.</span
                  ><span class="loading-s3 sm-j">.</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Vue from 'vue'
import { ref, defineEmits } from 'vue'
import { createToastInterface } from 'vue-toastification'
import { $t } from '@/helpers/translate'

const toast = createToastInterface()

const expense = ref({})
const errors = ref({ cost: false })
const loading = ref(false)

/**
 * Validate the expense and if there are no errors, save the expense to local storage, update the safe balance and close the popup
 */
const createExpense = () => {
  let error = 0
  if (typeof expense.value.cost === 'undefined' || expense.value.cost == 0) {
    error = 1
    errors.value.cost = true
  }
  if (error == 0) {
    toast.success($t('The expense has been registered successfully'), {
      position: 'top-center'
    })
    Vue.prototype.$addToLocalDatabase(expense.value, 'expenses')
    let safe = Vue.prototype.$database.safes.find((el) => el.id == expense.value.safe_id)
    if (safe) {
      safe.balance = parseFloat(safe.balance) - parseFloat(expense.value.cost)
      localStorage.database = JSON.stringify(Vue.prototype.$database)
    }
    emit('close')
  }
}

// Get pos id
const url = window.location.href
const segments = url.split('/')
const id = segments[segments.length - 2]

Vue.prototype.$updateDatabase(['expenseSections'])
let pointOfSale = Vue.prototype.$database.pointOfSales.find((el) => el.id == id)
expense.value = {
  invoice_group: pointOfSale.expenses_invoice_group_id,
  point_of_sale_id: pointOfSale.id,
  session_number: pointOfSale.session_number,
  safe_id: pointOfSale.safe_id,
  expense_section_id: 0,
  cost: '',
  date: Vue.prototype.$nowDate()
}

const filletedExpensesSections = Vue.prototype.$database.expenseSections.filter((el) =>
  JSON.parse(pointOfSale.expenses_section_id).includes(el.id)
)

const emit = defineEmits(['close'])
</script>
