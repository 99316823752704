var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'archway',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        {
          title: _vm.$t('Current balance'),
          value: this.$moneyFormat(_vm.item.cost)
        }
      ],"buttons":[
        {
          title: _vm.$t('Edit'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('capitals_edit')
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Origin information'),"cloumns":{
        firstCloum: [
          {
            icon: 'signature',
            title: _vm.$t('Original name'),
            value: _vm.item.name
          },
          {
            icon: 'minus-hexagon',
            title: _vm.$t('Depreciation rate'),
            value: _vm.item.depreciation_rate
          },
          {
            icon: 'calendar',
            title: _vm.$t('Purchase date'),
            value: _vm.item.date
          }
        ],
        scondCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Value'),
            value: this.$moneyFormat(_vm.item.cost)
          },
          {
            icon: 'toggle-on',
            title: _vm.$t('Status'),
            value: _vm.item.stat != 0 ? _vm.$t('effective') : _vm.$t('Ineffective')
          },
          {
            icon: 'calendar',
            title: _vm.$t('Date of sale'),
            value: _vm.item.expiry_date
          }
        ]
      },"paragraphs":[
        {
          title: _vm.$t('Description of the original'),
          value: _vm.item.descripton
        },
        { title: _vm.$t('Other detailed data'), value: _vm.item.details },
        { title: _vm.$t('Reviews'), value: _vm.item.notes }
      ]}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c('HistoryCard',{attrs:{"items":_vm.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }