<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :image="item.image"
        :icon="'user'"
        :title="item.name"
        :stat="item.stat"
        :statText="item.stat ? $t('Active') : $t('Inactive')"
        :subData="[
          {
            title: $t('Tax identification number'),
            value: item.tax_number
          }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $t('user.admin') || $t('user.role.customers_edit')
          },
          {
            title: $t('Comprehensive report'),
            icon: 'file-chart-pie',
            link: '/suppliersDetailsAccounts?supplier_id=' + $route.params.id,
            role: $checkRole('reports_show')
          },
          {
            title: $t('Add a batch'),
            icon: 'dollar-sign',
            link: '/purchasePayments/create?supplier_id=' + $route.params.id,
            role: $checkRole('purchase_payments_add')
          },
          {
            title: $t('Add a purchase bill'),
            icon: 'file-invoice-dollar',
            link: '/bills/create?supplier_id=' + $route.params.id,
            role: $checkRole('bills_add')
          },
          {
            title: $t('Add a request'),
            icon: 'briefcase',
            link: '/purchaseorders/create?supplier_id=' + $route.params.id,
            role: $checkRole('purchase_orders_add')
          }
        ]"
      />

      <div class="col-12">
        <showFile />
      </div>

      <showCard
        class="col-12"
        :title="$t('Background')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'user',
              title: $t('Supplier name'),
              value: item.name
            },
            {
              icon: 'dollar-sign',
              title: $t('Balance'),
              value: $moneyFormat($option.balance_type == 1 ? item.balance * -1 : item.balance)
            }
          ],
          scondCloum: [
            {
              icon: 'dollar-sign',
              title: $t('Opening balance'),
              value: $moneyFormat(item.opening_balance)
            },
            {
              icon: 'toggle-on',
              title: $t('Supplier status'),
              value: item.stat == 1 ? $t('effective') : $t('Ineffective')
            }
          ]
        }"
      />
      <showCard
        class="col-12"
        :title="$t('Billing address')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Address'),
              value: item.address1
            },
            {
              icon: 'location-arrow',
              title: $t('Governorate'),
              value: item.entity
            },
            {
              icon: 'map-marked',
              title: $t('Zip code'),
              value: item.zip
            }
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Itinerary'),
              value: item.address2
            },
            {
              icon: 'city',
              title: $t('City'),
              value: item.city
            },
            {
              icon: 'globe-americas',
              title: $t('State'),
              value: item.country
            }
          ]
        }"
      />
      <showCard
        class="col-12"
        :title="$t('Communication information')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'phone',
              title: $t('Mobile number'),
              value: item.mobile
            }
          ],
          scondCloum: [
            {
              icon: 'at',
              title: $t('Email'),
              value: item.email
            }
          ]
        }"
      />
      <div class="col-12">
        <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'
import notes from './components/notes.vue'
import contacts from './components/contacts/index.vue'

import showFile from '@/elements/show/showFile.vue'
import HistoryCard from '@/components/historyCard.vue'

export default {
  data() {
    return {
      path: '/suppliers',
      loaded: false,
      item: {}
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      var item = this.$database.suppliers.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.loaded = true
        axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
          this.item = response.data
          this.loaded = true
        })
      })
    }
  },
  components: {
    showHeader,
    showCard,
    notes,
    contacts,

    showFile,
    HistoryCard
  }
}
</script>
