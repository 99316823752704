<template>
  <div class="print-model">
    <div id="printMe" ref="printMe">
      <InvoiceComponent :item="item" :loading="loading" />
      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in options.invoices_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
      <!-- <p class="pioneer-data">تم الإنشاء بواسطة ثاني أثنين</p> -->
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import InvoiceComponent from './components/invoice.vue'
export default {
  data() {
    return {
      path: '/invoices',
      item: {},
      unitsCoefficients: [],
      loading: true
    }
  },
  methods: {
    getitem() {
      this.loading = true
      this.item = this.$parent.printedInvoice
      let unitsCoefficients = []
      unitsCoefficients = [...new Set(unitsCoefficients)]
      this.unitsCoefficients = this.$database.unitsCoefficients.filter((row) =>
        unitsCoefficients.includes(row.id)
      )
      this.unitsCoefficients = this.unitsCoefficients.sort(
        this.$dynamicSort({ orderBy: 'id', orderType: 'desc' })
      )
      this.loading = false
      var myel = this
      setTimeout(function () {
        var text = document.getElementById('printMe').innerHTML
        document.getElementById('printMe').remove()
        var myframe = document.getElementById('inviceIframe')
        myframe.contentDocument.body.innerHTML = text
        setTimeout(function () {
          myframe.focus()
          myframe.contentWindow.print()
          myel.colse()
        }, 100)
      }, 20)
    },
    colse() {
      this.$parent.printedInvoice = false
    }
  },
  mounted() {
    this.getitem()
  },
  props: ['options'],
  components: {
    QrcodeVue,
    InvoiceComponent
  }
}
</script>
