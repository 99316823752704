<template>
  <div class="card show-card active">
    <div class="card-header">
      <h4 class="card-header-title">
        {{ $t('Transaction log') }}
      </h4>
    </div>
    <div class="card-body">
      <div class="list-group list-group-flush list-group-activity my-n3" v-if="items?.length">
        <div class="list-group-item d-flex" v-for="item in items">
          <template v-if="item.action == 'create'">
            <div class="avatar avatar-sm mr-2">
              <div class="avatar-title font-size-lg bg-success-soft rounded-circle text-success">
                <i class="far fa-plus"></i>
              </div>
            </div>
            <div class="m-2 ml-n2 w-100">
              <h4 class="mb-1">
                {{ $t('Create item') }}
              </h4>
              <h5 class="small text-gray-800">
                {{ formatDate(item.created_at) }}
              </h5>
              <p class="small text-gray-700 mt-1">
                {{ $t('Item created by') }} <strong>{{ item.user.name }}</strong>
              </p>
              <div class="row">
                <div class="col-12">
                    <h5 lass="small text-gray-800">
                        {{ $t('Details') }}
                    </h5>
                    <pre class="small text-gray-700 border rounded p-2">
                        {{ JSON.parse(item.details).new }}
                    </pre>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="item.action == 'update'">
            <div class="avatar avatar-sm mr-2">
              <div class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                <i class="far fa-edit"></i>
              </div>
            </div>
            <div class="m-2 ml-n2 w-100">
              <h4 class="mb-1">
                {{ $t('Edit item') }}
              </h4>
              <h5 class="small text-gray-800">
                {{ formatDate(item.created_at) }}
              </h5>
              <p class="small text-gray-700 mt-1">
                {{ $t('Item edited by') }} <strong>{{ item.user.name }}</strong>
              </p>
              <div class="row">
                <div class="col-6">
                    <h5 lass="small text-gray-800">
                        {{ $t('Before') }}
                    </h5>
                    <pre class="small text-gray-700 border rounded p-2">
                        {{ JSON.parse(item.details).old }}
                    </pre>
                </div>
                <div class="col-6">
                    <h5 lass="small text-gray-800">
                        {{ $t('After') }}
                    </h5>
                    <pre class="small text-gray-700 border rounded p-2">
                        {{ JSON.parse(item.details).new }}
                    </pre>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="item.action == 'delete'">
            <div class="avatar avatar-sm mr-2">
              <div class="avatar-title font-size-lg bg-danger-soft rounded-circle text-danger">
                <i class="far fa-trash"></i>
              </div>
            </div>
            <div class="m-2 ml-n2">
              <h4 class="mb-1">
                {{ $t('Delete item') }}
              </h4>
              <h5 class="small text-gray-800">
                {{ formatDate(item.created_at) }}
              </h5>
              <p class="small text-gray-700 mb-0 mt-1">
                {{ $t('Item deleted by') }} <strong>{{ item.user.name }}</strong>
              </p>
            </div>
          </template>
        </div>
      </div>
      <div class="p-3 text-center" v-else>
        <p>{{ $t('There is no log') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const formatDate = (dateString, locale = 'en-US', options = {}) => {
  const date = new Date(dateString)

  // Default options if none provided
  const defaultOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  }

  return date.toLocaleDateString(locale, { ...defaultOptions, ...options })
}

const props = defineProps(['items'])
</script>
