import Vue from 'vue'
import ar from '@/locales/ar.json'
import en from '@/locales/en.json'

export const $t = (key) => {
  if (Vue.prototype.$user.language == 'en') {
    return en[key] ?? key
  } else {
    return ar[key] ?? key
  }
}
