<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Create Role')"
          :description="$t('From Here You Can Create A New UserRole')"
          v-if="$route.name == 'rolesCreate'"
        />

        <addHeader
          :title="$t('Edit Role')"
          :description="$t('From Here You Can Modify The Users Roles')"
          v-if="$route.name == 'rolesEdit'"
        />
        <div class="mb-4 row">
          <div class="card w-100 h-auto">
            <div class="card-header">
              <dvider class="dic-role" :title="$t('Basic Information')" noLine="true" />
            </div>
            <hr />
            <div class="card-body">
              <formInput
                :title="$t('Role Name')"
                v-model="item.name"
                :col="'col-12'"
                :hasErorr="errors.name"
                :error="$t('This field Is required')"
                maxlength="255"
              />
              <formTextarea :title="$t('Description of role')" v-model="item.discription" />
            </div>
          </div>

          <!-- <TabNav
            :tabs="['المبيعات', 'المشتريات', 'الخزائن', 'المخازن']"
            :selected="selected"
            @selected="setSelected"
          >
            <Tab :isSelected="selected === 'المبيعات'"> </Tab>
            <Tab :isSelected="selected === 'المشتريات'"> settings </Tab>
          </TabNav> -->
          <div class="card w-100 h-auto">
            <div class="card-header">
              {{ $t('Options') }}
            </div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('Companies')"
                :icon="'city'"
                :values="[
                  { name: $t('view'), key: 'companies_show' },
                  { name: $t('Add'), key: 'companies_add' },
                  { name: $t('Edit'), key: 'companies_edit' },
                  { name: $t('Delete'), key: 'companies_delete' }
                ]"
              />
              <roleGroupInput
                :title="$t('users')"
                :icon="'user-chart'"
                :values="[
                  { name: $t('view'), key: 'users_show' },
                  { name: $t('Add'), key: 'users_add' },
                  { name: $t('Edit'), key: 'users_edit' },
                  { name: $t('Delete'), key: 'users_delete' }
                ]"
              />
              <roleGroupInput
                :title="$t('roles')"
                :icon="'users-class'"
                :values="[
                  { name: $t('view'), key: 'roles_show' },
                  { name: $t('Add'), key: 'roles_add' },
                  { name: $t('Edit'), key: 'roles_edit' },
                  { name: $t('Delete'), key: 'roles_delete' }
                ]"
              />
            </div>
          </div>

          <hr />
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Sales') }}</div>
            <hr />
            <div class="card-body">
              <!-- <small class="form-text text-muted mb-4 col-12">{{
                $t("theInputs")
              }}</small> -->
              <roleGroupInput
                :title="$t('customers')"
                v-if="$site.customers_allow"
                :icon="'user-alt'"
                :values="[
                  { name: $t('view'), key: 'customers_show' },
                  { name: $t('Add'), key: 'customers_add' },
                  { name: $t('Edit'), key: 'customers_edit' },
                  { name: $t('Delete'), key: 'customers_delete' }
                ]"
              />
              <hr />
              <roleGroupInput
                :title="$t('estimates')"
                v-if="$site.quotations_allow"
                :icon="'calculator'"
                :values="[
                  { name: $t('view'), key: 'quotations_show' },
                  {
                    name: $t('Selected Width'),
                    key: 'quotations_show_allow'
                  },
                  { name: $t('Add'), key: 'quotations_add' },
                  {
                    name: $t('change Product'),
                    key: 'quotations_edit_product_show'
                  },
                  { name: $t('Edit'), key: 'quotations_edit' },
                  { name: $t('Delete'), key: 'quotations_delete' },
                  {
                    name: $t('Transfer To Invoice'),
                    key: 'quotations_turn_into_invoice_show'
                  }
                ]"
              />
              <hr class="col-12" v-if="$site.quotations_allow" />
              <div class="form-group col-md-6 row" v-if="$site.quotations_allow">
                <label class="col-md-4">{{ $t('Available Cases') }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.quotations_allow_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 }
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Available For Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <div class="form-group col-md-6 row" v-if="$site.quotations_allow">
                <label class="col-md-4">{{ $t('The Cases Shown') }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.quotations_show_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 }
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Presented To Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <hr class="col-12" v-if="$site.quotations_allow" />
              <roleGroupInput
                :title="$t('invoices')"
                :icon="'file-invoice-dollar'"
                v-if="$site.invoices_allow"
                :values="[
                  { name: $t('view'), key: 'invoices_show' },
                  { name: $t('Add'), key: 'invoices_add' },
                  {
                    name: $t('Selected width'),
                    key: 'invoices_show_allow'
                  },
                  { name: $t('Edit'), key: 'invoices_edit' },
                  {
                    name: $t('change Product'),
                    key: 'invoices_edit_product'
                  },
                  { name: $t('Delete'), key: 'invoices_delete' },
                  {
                    name: $t('shortSale'),
                    key: 'invoices_short_sale'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Sale Returns')"
                :icon="'exchange'"
                :values="[
                  { name: $t('view'), key: 'sale_returns_show' },
                  { name: $t('Add'), key: 'sale_returns_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'sale_returns_show_allow'
                  },
                  { name: $t('Edit'), key: 'sale_returns_edit' },
                  {
                    name: $t('change Product'),
                    key: 'sale_returns_edit_product'
                  },
                  { name: $t('Delete'), key: 'sale_returns_delete' },
                  {
                    name: $t('shortSale'),
                    key: 'sale_returns_short_sale'
                  }
                ]"
              />

              <hr class="col-12" v-if="$site.quotations_allow" />
              <roleGroupInput
                :title="$t('saleOrders')"
                :icon="'briefcase'"
                v-if="$site.orders_allow"
                :values="[
                  { name: $t('view'), key: 'orders_show' },
                  { name: $t('Add'), key: 'orders_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'orders_show_allow'
                  },
                  { name: $t('editDate'), key: 'orders_edit_date' },
                  {
                    name: $t('change Product'),
                    key: 'orders_edit_product'
                  },
                  { name: $t('Edit'), key: 'orders_edit' },
                  { name: $t('Delete'), key: 'orders_delete' },
                  {
                    name: $t('Transfer To Invoice'),
                    key: 'orders_delete'
                  }
                ]"
              />

              <hr class="col-12" />
            </div>
          </div>
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Purchases') }}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('Suppliers')"
                :icon="'user-tie'"
                v-if="$site.suppliers_allow"
                :values="[
                  { name: $t('view'), key: 'suppliers_show' },
                  { name: $t('Add'), key: 'suppliers_add' },
                  { name: $t('Edit'), key: 'suppliers_edit' },
                  { name: $t('Delete'), key: 'suppliers_delete' }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Purchase Bills')"
                :icon="'receipt'"
                v-if="$site.bills_allow"
                :values="[
                  { name: $t('view'), key: 'bills_show' },
                  { name: $t('Add'), key: 'bills_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'bills_show_allow'
                  },
                  {
                    name: $t('change Product'),
                    key: 'bills_edit_product'
                  },
                  { name: $t('Edit'), key: 'bills_edit' },
                  {
                    name: $t('Delete'),
                    key: 'bills_delete'
                  }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Purchase Returns')"
                :icon="'exchange'"
                :values="[
                  { name: $t('view'), key: 'purchase_returns_show' },
                  { name: $t('Add'), key: 'purchase_returns_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'purchase_returns_show_allow'
                  },
                  { name: $t('Edit'), key: 'purchase_returns_edit' },
                  {
                    name: $t('change Product'),
                    key: 'purchase_returns_edit_product'
                  },
                  {
                    name: $t('Delete'),
                    key: 'purchase_returns_delete'
                  },
                  {
                    name: $t('shortSale'),
                    key: 'purchase_returns_short_sale'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('purchase Orders')"
                :icon="'briefcase'"
                :values="[
                  { name: $t('view'), key: 'purchase_orders_show' },
                  { name: $t('Add'), key: 'purchase_orders_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'purchase_orders_show_allow'
                  },
                  {
                    name: $t('change Product'),
                    key: 'purchase_orders_edit_product'
                  },
                  { name: $t('Edit'), key: 'purchase_orders_edit' },
                  {
                    name: $t('Delete'),
                    key: 'purchase_orders_delete'
                  }
                ]"
              />
            </div>
          </div>
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Safes') }}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('safes')"
                :icon="'treasure-chest'"
                :values="[
                  { name: $t('view'), key: 'safes_show' },
                  { name: $t('Add'), key: 'safes_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'safes_show_allow'
                  },
                  { name: $t('Edit'), key: 'safes_edit' },
                  { name: $t('Delete'), key: 'safes_delete' }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('bank Accounts')"
                :icon="'university'"
                :values="[
                  { name: $t('view'), key: 'bank_accounts_show' },
                  { name: $t('Add'), key: 'bank_accounts_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'bank_accounts_show_allow'
                  },
                  { name: $t('Edit'), key: 'bank_accounts_edit' },
                  {
                    name: $t('Delete'),
                    key: 'bank_accounts_delete'
                  }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('sale Payments')"
                :icon="'credit-card-front'"
                :values="[
                  { name: $t('view'), key: 'sales_payments_show' },
                  { name: $t('Add'), key: 'sales_payments_add' },
                  {
                    name: $t('Selected width'),
                    key: 'sales_payments_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'sales_payments_edit_item'
                  },
                  { name: $t('Edit'), key: 'sales_payments_edit' },
                  {
                    name: $t('Delete'),
                    key: 'sales_payments_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('purchase Payments')"
                :icon="'credit-card-front'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'purchase_payments_show'
                  },
                  { name: $t('Add'), key: 'purchase_payments_add' },
                  {
                    name: $t('Selected width'),
                    key: 'purchase_payments_show_allow'
                  },
                  {
                    name: $t('Change options'),
                    key: 'purchase_payments_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'purchase_payments_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'purchase_payments_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('expenses')"
                :icon="'usd-square'"
                :values="[
                  { name: $t('view'), key: 'expenses_show' },
                  { name: $t('Add'), key: 'expenses_add' },
                  {
                    name: $t('Selected width'),
                    key: 'expenses_show_allow'
                  },
                  { name: $t('Edit'), key: 'expenses_edit' },
                  { name: $t('Delete'), key: 'expenses_delete' }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('safes')"
                :icon="'treasure-chest'"
                v-if="$site.safes_allow"
                :values="[
                  { name: $t('view'), key: 'safes_show' },
                  { name: $t('Add'), key: 'safes_add' },
                  {
                    name: $t('Selected width'),
                    key: 'safes_show_allow'
                  },
                  { name: $t('Edit'), key: 'safes_edit' },
                  { name: $t('Delete'), key: 'safes_delete' }
                ]"
              />
              <hr class="col-12" v-if="$site.safes_allow" />
              <roleGroupInput
                :title="$t('deposit Lockers')"
                :icon="'envelope-open-dollar'"
                :values="[
                  { name: $t('view'), key: 'safes_deposits_show' },
                  { name: $t('Add'), key: 'safes_deposits_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'safes_deposits_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'safes_deposits_edit_item'
                  },
                  { name: $t('Edit'), key: 'safes_deposits_edit' },
                  {
                    name: $t('Delete'),
                    key: 'safes_deposits_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('pull Out Lockers')"
                :icon="'envelope-open-dollar'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'safes_withdrawals_show'
                  },
                  { name: $t('Add'), key: 'safes_withdrawals_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'safes_withdrawals_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'safes_withdrawals_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'safes_withdrawals_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'safes_withdrawals_delete'
                  }
                ]"
              />
              <hr class="col-12" />

              <roleGroupInput
                :title="$t('Moving Lockers')"
                :icon="'envelope-open-dollar'"
                :values="[
                  { name: $t('view'), key: 'safes_transfers_show' },
                  { name: $t('Add'), key: 'safes_transfers_add' },
                  {
                    name: $t('Selected width'),
                    key: 'safes_transfers_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'safes_transfers_edit_item'
                  },
                  { name: $t('Edit'), key: 'safes_transfers_edit' },
                  {
                    name: $t('Delete'),
                    key: 'safes_transfers_delete'
                  }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Tax payment')"
                :icon="'usd-square'"
                :values="[
                  { name: $t('view'), key: 'tax_payments_show' },
                  { name: $t('Add'), key: 'tax_payments_add' },

                  { name: $t('Edit'), key: 'tax_payments_edit' },
                  { name: $t('Delete'), key: 'tax_payments_delete' }
                ]"
              />
              <hr class="col-12" />
            </div>
          </div>
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Stores') }}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('stores')"
                :icon="'store-alt'"
                :values="[
                  { name: $t('view'), key: 'stores_show' },
                  {
                    name: $t('Selected Width'),
                    key: 'stores_show_allow'
                  },
                  { name: $t('Add'), key: 'stores_add' },
                  { name: $t('Edit'), key: 'stores_edit' },
                  {
                    name: $t('Products Transportation'),
                    key: 'stores_move_products'
                  },
                  {
                    name: $t('add Products'),
                    key: 'stores_add_products'
                  },
                  {
                    name: $t('withdraw Products'),
                    key: 'stores_remove_products'
                  },
                  { name: $t('Delete'), key: 'stores_delete' }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('products')"
                :icon="'inventory'"
                :values="[
                  { name: $t('view'), key: 'products_show' },
                  { name: $t('Add'), key: 'products_add' },
                  { name: $t('Edit'), key: 'products_edit' },
                  { name: $t('Cost'), key: 'products_cost' },
                  { name: $t('Delete'), key: 'products_delete' }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Warehouse Deposit')"
                :icon="'boxes-alt'"
                :values="[
                  { name: $t('view'), key: 'stores_deposits_show' },
                  { name: $t('Add'), key: 'stores_deposits_add' },
                  {
                    name: $t('Selected width'),
                    key: 'stores_deposits_show_allow'
                  },
                  {
                    name: $t('Change options'),
                    key: 'stores_deposits_edit_item'
                  },
                  { name: $t('Edit'), key: 'stores_deposits_edit' },
                  {
                    name: $t('Delete'),
                    key: 'stores_deposits_delete'
                  }
                ]"
              />
              <hr class="col-12" />

              <roleGroupInput
                :title="$t('Store Withdrawals')"
                :icon="'boxes-alt'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'stores_withdrawals_show'
                  },
                  { name: $t('Add'), key: 'stores_withdrawals_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'stores_withdrawals_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'stores_withdrawals_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'stores_withdrawals_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'stores_withdrawals_delete'
                  }
                ]"
              />
              <hr class="col-12" />

              <roleGroupInput
                :title="$t('Warehouse Transfer')"
                :icon="'boxes-alt'"
                :values="[
                  { name: $t('view'), key: 'stores_transfers_show' },
                  { name: $t('Add'), key: 'stores_transfers_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'stores_transfers_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'stores_transfers_edit_item'
                  },
                  { name: $t('Edit'), key: 'stores_transfers_edit' },
                  {
                    name: $t('Delete'),
                    key: 'stores_transfers_delete'
                  }
                ]"
              />
              <hr class="col-12" />

              <roleGroupInput
                :title="$t('Stores Loading')"
                :icon="'boxes-alt'"
                :values="[
                  { name: $t('view'), key: 'stores_orders_show' },
                  { name: $t('Add'), key: 'stores_orders_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'stores_orders_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'stores_orders_edit_item'
                  },
                  { name: $t('Edit'), key: 'stores_orders_edit' },
                  {
                    name: $t('Delete'),
                    key: 'stores_orders_delete'
                  }
                ]"
              />
            </div>
          </div>
          <!-- <hr class="col-12" /> -->
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Employees') }}</div>
            <hr />
            <div class="card-body">
              <!-- <hr class="col-12" /> -->
              <roleGroupInput
                :title="$t('employees')"
                :icon="'user-cog'"
                :values="[
                  { name: $t('view'), key: 'employees_show' },
                  { name: $t('Add'), key: 'employees_add' },
                  { name: $t('Edit'), key: 'employees_edit' },
                  { name: $t('Delete'), key: 'employees_delete' }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Employee Salaries')"
                :icon="'user-chart'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'employees_salaries_show'
                  },
                  { name: $t('Add'), key: 'employees_salaries_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'employees_salaries_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'employees_salaries_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'employees_salaries_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'employees_salaries_delete'
                  }
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('employee Incentives')"
                :icon="'user-chart'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'employees_incentives_show'
                  },
                  {
                    name: $t('Add'),
                    key: 'employees_incentives_add'
                  },
                  {
                    name: $t('Selected Width'),
                    key: 'employees_incentives_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'employees_incentives_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'employees_incentives_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'employees_incentives_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('employee Discounts')"
                :icon="'user-chart'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'employees_deductions_show'
                  },
                  {
                    name: $t('Add'),
                    key: 'employees_deductions_add'
                  },
                  {
                    name: $t('Selected Width'),
                    key: 'employees_deductions_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'employees_deductions_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'employees_deductions_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'employees_deductions_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('staff Draws')"
                :icon="'user-chart'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'employees_withdrawals_show'
                  },
                  {
                    name: $t('Add'),
                    key: 'employees_withdrawals_add'
                  },
                  {
                    name: $t('Selected Width'),
                    key: 'employees_withdrawals_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'employees_withdrawals_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'employees_withdrawals_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'employees_withdrawals_delete'
                  }
                ]"
              />
            </div>
          </div>
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Partners') }}</div>
            <hr />
            <div class="card-body">
              <!-- <hr class="col-12" /> -->
              <roleGroupInput
                :title="$t('Partners')"
                :icon="'handshake'"
                :values="[
                  { name: $t('view'), key: 'partners_show' },
                  { name: $t('Add'), key: 'partners_add' },
                  { name: $t('Edit'), key: 'partners_edit' },
                  { name: $t('Delete'), key: 'partners_delete' }
                ]"
              />
              <hr class="col-12" v-if="$site.partners_allow" />
              <roleGroupInput
                :title="$t('Partners Deposit')"
                :icon="'handshake'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'partners_deposits_show'
                  },
                  { name: $t('Add'), key: 'partners_deposits_add' },
                  {
                    name: $t('Selected width'),
                    key: 'partners_deposits_show_allow'
                  },
                  {
                    name: $t('Change options'),
                    key: 'partners_deposits_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'partners_deposits_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'partners_deposits_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Withdrawal Of Partners')"
                :icon="'handshake'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'partners_withdrawals_show'
                  },
                  {
                    name: $t('Add'),
                    key: 'partners_withdrawals_add'
                  },
                  {
                    name: $t('Selected width'),
                    key: 'partners_withdrawals_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'partners_withdrawals_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'partners_withdrawals_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'partners_withdrawals_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Partners Profits')"
                :icon="'handshake'"
                :values="[
                  { name: $t('view'), key: 'partners_profits_show' },
                  { name: $t('Add'), key: 'partners_profits_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'partners_profits_show_allow'
                  },
                  {
                    name: $t('Change options'),
                    key: 'partners_profits_edit_item'
                  },
                  { name: $t('Edit'), key: 'partners_profits_edit' },
                  {
                    name: $t('Delete'),
                    key: 'partners_profits_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('partners Settlements')"
                :icon="'handshake'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'partners_settlements_show'
                  },
                  {
                    name: $t('Add'),
                    key: 'partners_settlements_add'
                  },
                  {
                    name: $t('Selected Width'),
                    key: 'partners_settlements_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'partners_settlements_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'partners_settlements_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'partners_settlements_delete'
                  }
                ]"
              />
            </div>
          </div>

          <!-- <div class="card w-100 h-auto">
            <div class="card-header">{{$t('Projects')}}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('Projects')"
                :icon="'chart-line'"
                :values="[
                  { name: $t('view'), key: 'projects_show' },
                  { name: $t('Add'), key: 'projects_add' },
                  { name: $t('Edit'), key: 'projects_edit' },
                  { name: $t('Delete'), key: 'projects_delete' },
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('projectsDeposit')"
                :icon="'chart-line'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'projects_deposits_show',
                  },
                  { name: $t('Add'), key: 'projects_deposits_add' },
                  {
                    name: $t('Selected width'),
                    key: 'projects_deposits_show_allow',
                  },
                  {
                    name: $t('Change Options'),
                    key: 'projects_deposits_edit_item',
                  },
                  {
                    name: $t('Edit'),
                    key: 'projects_deposits_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'projects_deposits_delete',
                  },
                ]"
              />
              <hr class="col-12" />
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("Available Cases")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.projects_deposits_allow_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Available For Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("TheCases Shown")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.projects_deposits_show_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Presented To Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Project Withdrawal')"
                :icon="'chart-line'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'projects_withdrawals_show',
                  },
                  {
                    name: $t('Add'),
                    key: 'projects_withdrawals_add',
                  },
                  {
                    name: $t('Selected Width'),
                    key: 'projects_withdrawals_show_allow',
                  },
                  {
                    name: $t('Change Options'),
                    key: 'projects_withdrawals_edit_item',
                  },
                  {
                    name: $t('Edit'),
                    key: 'projects_withdrawals_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'projects_withdrawals_delete',
                  },
                ]"
              />
              <hr class="col-12" />
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("Available Cases")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.projects_withdrawals_allow_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Available For Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("The Cases Shown")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.projects_withdrawals_show_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Presented To Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Transfer Projects')"
                :icon="'chart-line'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'projects_transfers_show',
                  },
                  { name: $t('Add'), key: 'projects_transfers_add' },
                  {
                    name: $t('Selected Width'),
                    key: 'projects_transfers_show_allow',
                  },
                  {
                    name: $t('Change Options'),
                    key: 'projects_transfers_edit_item',
                  },
                  {
                    name: $t('Edit'),
                    key: 'projects_transfers_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'projects_transfers_delete',
                  },
                ]"
              />
              <hr class="col-12" />
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("Available Cases")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.projects_transfers_allow_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Available For Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("The Cases Shown")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.projects_transfers_show_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Presented To Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Subscriptions') }}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('Subscriptions')"
                :icon="'history'"
                :values="[
                  { name: $t('view'), key: 'subscriptions_show' },
                  { name: $t('Add'), key: 'subscriptions_add' },
                  { name: $t('Edit'), key: 'subscriptions_edit' },
                  {
                    name: $t('Delete'),
                    key: 'subscriptions_delete',
                  },
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('subscriptions Operations')"
                :icon="'stopwatch'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'subscriptions_operations_show',
                  },
                  {
                    name: $t('Add'),
                    key: 'subscriptions_operations_add',
                  },
                  {
                    name: $t('Edit'),
                    key: 'subscriptions_operations_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'subscriptions_operations_delete',
                  },
                ]"
              />
            </div>
          </div> -->
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Cheques') }}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('Sales cheques')"
                :icon="'money-check-alt'"
                :values="[
                  { name: $t('view'), key: 'sales_cheques_show' },
                  { name: $t('Add'), key: 'sales_cheques_add' },
                  {
                    name: $t('Selected width'),
                    key: 'sales_cheques_show_allow'
                  },
                  {
                    name: $t('Change options'),
                    key: '.sales_cheques_edit_item'
                  },
                  { name: $t('Edit'), key: 'sales_cheques_edit' },
                  {
                    name: $t('Delete'),
                    key: 'sales_cheques_delete'
                  }
                ]"
              />
              <hr class="col-12" />

              <roleGroupInput
                :title="$t('purchase Cheques')"
                :icon="'money-check-alt'"
                :values="[
                  { name: $t('view'), key: 'purchase_cheques_show' },
                  { name: $t('Add'), key: 'purchase_cheques_add' },
                  {
                    name: $t('Selected width'),
                    key: 'purchase_cheques_show_allow'
                  },
                  {
                    name: $t('Change Options'),
                    key: 'purchase_cheques_edit_item'
                  },
                  { name: $t('Edit'), key: 'purchase_cheques_edit' },
                  {
                    name: $t('Delete'),
                    key: 'purchase_cheques_delete'
                  }
                ]"
              />
              <hr class="col-12" />
            </div>
          </div>
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Discounts') }}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('Sales Discounts')"
                :icon="'badge-percent'"
                :values="[
                  { name: $t('view'), key: 'sales_discounts_show' },
                  { name: $t('Add'), key: 'sales_discounts_add' },
                  {
                    name: $t('Selected width'),
                    key: 'sales_discounts_show_allow'
                  },
                  {
                    name: $t('Change options'),
                    key: 'sales_discounts_edit_item'
                  },
                  { name: $t('Edit'), key: 'sales_discounts_edit' },
                  {
                    name: $t('Delete'),
                    key: 'sales_discounts_delete'
                  }
                ]"
              />

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('Purchase discounts')"
                :icon="'badge-percent'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'purchase_discounts_show'
                  },
                  { name: $t('Add'), key: 'purchase_discounts_add' },
                  {
                    name: $t('Selected width'),
                    key: 'purchase_discounts_show_allow'
                  },
                  {
                    name: $t('Change options'),
                    key: 'purchase_discounts_edit_item'
                  },
                  {
                    name: $t('Edit'),
                    key: 'purchase_discounts_edit'
                  },
                  {
                    name: $t('Delete'),
                    key: 'purchase_discounts_delete'
                  }
                ]"
              />
              <hr class="col-12" />
            </div>
          </div>
          <!-- <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Manufacturing processes') }}</div>
            <hr />
            <div class="card-body">
              <roleGroupInput
                :title="$t('Manufacturing processes')"
                :icon="'industry-alt'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'manufacturing_processes_show',
                  },
                  {
                    name: $t('Add'),
                    key: 'manufacturing_processes_add',
                  },
                  {
                    name: $t('Selected width'),
                    key: 'manufacturing_processes_show_allow',
                  },
                  {
                    name: $t('Edit'),
                    key: 'manufacturing_processes_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'manufacturing_processes_delete',
                  },
                ]"
              />
              <hr class="col-12" />
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("Available cases")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.manufacturing_processes_allow_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Available For Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("TheCasesShown")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.manufacturing_processes_show_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Presented To Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>

              <hr class="col-12" />
              <roleGroupInput
                :title="$t('manufacturingModels')"
                :icon="'hammer'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'manufacturing_models_show',
                  },
                  {
                    name: $t('Add'),
                    key: 'manufacturing_models_add',
                  },
                  {
                    name: $t('Selected width'),
                    key: 'manufacturing_models_show_allow',
                  },
                  {
                    name: $t('Change options'),
                    key: 'manufacturing_models_edit_item',
                  },
                  {
                    name: $t('Edit'),
                    key: 'manufacturing_models_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'manufacturing_models_delete',
                  },
                ]"
              />
              <hr class="col-12" />
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("Available cases")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.manufacturing_models_allow_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Available For Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("TheCasesShown")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.manufacturing_models_show_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Presented To Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <div class="card w-100 h-auto">
            <div class="card-header">{{ $t('Processes') }}</div>
            <hr />
            <div class="card-body">
              <!-- <roleGroupInput
                :title="$t('transfer Accounts')"
                :icon="'random'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'accounts_transfers_show',
                  },
                  { name: $t('Add'), key: 'accounts_transfers_add' },
                  {
                    name: $t('Edit'),
                    key: 'accounts_transfers_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'accounts_transfers_delete',
                  },
                ]"
              />
              <hr class="col-12" />
              <roleGroupInput
                :title="$t('operation List')"
                :icon="'hammer'"
                :values="[
                  {
                    name: $t('view'),
                    key: 'manufacturing_operations_show',
                  },
                  {
                    name: $t('Add'),
                    key: 'manufacturing_operations_add',
                  },
                  {
                    name: $t('Selected width'),
                    key: 'manufacturing_operations_show_allow',
                  },
                  {
                    name: $t('Change options'),
                    key: 'manufacturing_operations_edit_item',
                  },
                  {
                    name: $t('Edit'),
                    key: 'manufacturing_operations_edit',
                  },
                  {
                    name: $t('Delete'),
                    key: 'manufacturing_operations_delete',
                  },
                ]"
              /> -->
              <!-- <hr class="col-12" /> -->
              <!-- <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("Available cases")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.manufacturing_operations_allow_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Available For Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-md-6 row"
               
              >
                <label class="col-md-4">{{
                  $t("TheCasesShown")
                }}</label>
                <div class="col-md-7">
                  <multiselect
                    v-model="item.manufacturing_operations_show_status"
                    :options="[
                      { name: $t('Draft'), id: 0 },
                      { name: $t('Approved'), id: 1 },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('Cases Presented To Order')"
                    label="name"
                    track-by="id"
                    :hide-selected="true"
                  />
                </div>
              </div> -->

              <!-- <hr class="col-12" /> -->
              <roleGroupInput
                :title="$t('capitals')"
                :icon="'archway'"
                :values="[
                  { name: $t('view'), key: 'capitals_show' },
                  { name: $t('Add'), key: 'capitals_add' },
                  { name: $t('Edit'), key: 'capitals_edit' },
                  { name: $t('Delete'), key: 'capitals_delete' }
                ]"
              />

              <hr class="col-12" />
              <!-- <roleGroupInput
                :title="$t('pointsOfSale')"
                :icon="'cash-register'"
                :values="[
                  { name: $t('view'), key: 'point_of_sales_show' },
                  { name: $t('Add'), key: 'point_of_sales_add' },
                  {
                    name: $t('Selected width'),
                    key: 'point_of_sales_show_allow',
                  },
                  {
                    name: $t('BalanceView'),
                    key: 'point_of_sales_show_balance',
                  },
                  { name: $t('Edit'), key: 'point_of_sales_edit' },
                  {
                    name: $t('Delete'),
                    key: 'point_of_sales_delete',
                  },
                ]"
              />
              <roleGroupInput
                :title="$t('Staff')"
                :icon="'user-chart'"
                :values="[
                  { name: $t('view'), key: 'staff_show' },
                  { name: $t('Add'), key: 'staff_add' },
                  { name: $t('Edit'), key: 'staff_edit' },
                  { name: $t('Delete'), key: 'staff_delete' },
                ]"
              /> -->
              <!-- <roleGroupInput
                :title="$t('endorsements')"
                :icon="'file-signature'"
                :values="[
                  { name: $t('view'), key: 'endorsements_show' },
                  {
                    name: $t('Selected width'),
                    key: 'endorsements_show_allow',
                  },
                  { name: $t('Add'), key: 'endorsements_add' },
                  { name: $t('Edit'), key: 'endorsements_edit' },
                  { name: $t('Delete'), key: 'endorsements_delete' },
                ]"
              /> -->
              <!-- <roleGroupInput
                :title="$t('deposits')"
                :icon="'wallet'"
                :values="[
                  { name: $t('view'), key: 'deposits_show' },
                  {
                    name: $t('Selected width'),
                    key: 'deposits_show_allow',
                  },
                  { name: $t('Add'), key: 'deposits_add' },
                  { name: $t('Edit'), key: 'deposits_edit' },
                  { name: $t('Delete'), key: 'deposits_delete' },
                ]"
              /> -->
            </div>
          </div>

          <hr class="mt-5 mb-5 col-12" />
          <small class="form-text text-muted mb-4 col-12">{{ $t('Information') }}</small>

          <div class="col-12">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="reports_show" class="bg-check-label">
                  <i class="far fa-user-chart"></i>
                  {{ $t('reports') }} :
                </label>
                <input
                  type="checkbox"
                  id="reports_show"
                  v-model="item.reports_show"
                  class="custom-control-input"
                  value="1"
                />
                <label for="reports_show" class="custom-control-label"></label>
              </div>
            </label>
          </div>

          <div class="col-12" v-if="item.reports_show">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="reports_show" class="bg-check-label">
                  <i class="far fa-user-chart"></i>
                  {{ $t('Show Selected Reports') }} :
                </label>
                <input
                  type="checkbox"
                  id="reports_show_allow"
                  v-model="item.reports_show_allow"
                  class="custom-control-input"
                  value="1"
                />
                <label for="reports_show_allow" class="custom-control-label"></label>
              </div>
            </label>
          </div>

          <div class="col-12" v-if="item.reports_show">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="reports_earning_show" class="bg-check-label">
                  <i class="far fa-user-chart"></i>
                  {{ $t('View Earnings In Reports') }} :
                </label>
                <input
                  type="checkbox"
                  id="reports_earning_show"
                  v-model="item.reports_earning_show"
                  class="custom-control-input"
                  value="1"
                />
                <label for="reports_earning_show" class="custom-control-label"></label>
              </div>
            </label>
          </div>

          <div class="col-12">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="accounting_show" class="bg-check-label">
                  <i class="far fa-balance-scale"></i>
                  {{ $t('Accounting') }} :
                </label>
                <input
                  type="checkbox"
                  id="accounting_show"
                  v-model="item.accounting_show"
                  class="custom-control-input"
                  value="1"
                />
                <label for="accounting_show" class="custom-control-label"></label>
              </div>
            </label>
          </div>

          <div class="col-12">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="options_show" class="bg-check-label">
                  <i class="far fa-cog"></i> {{ $t('Settings') }}:
                </label>
                <input
                  type="checkbox"
                  id="options_show"
                  v-model="item.options_show"
                  class="custom-control-input"
                  value="1"
                />
                <label for="options_show" class="custom-control-label"></label>
              </div>
            </label>
          </div>

          <div class="col-12">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="profile_edit" class="bg-check-label">
                  <i class="far fa-cog"></i>{{ $t('User Settings') }}:
                </label>
                <input
                  type="checkbox"
                  id="profile_edit"
                  v-model="item.profile_edit"
                  class="custom-control-input"
                  value="1"
                />
                <label for="profile_edit" class="custom-control-label"></label>
              </div>
            </label>
          </div>

          <div class="col-12">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="history_show" class="bg-check-label">
                  <i class="far fa-database"></i> {{ $t('Items history') }}:
                </label>
                <input
                  type="checkbox"
                  id="history_show"
                  v-model="item.history_show"
                  class="custom-control-input"
                  value="1"
                />
                <label for="history_show" class="custom-control-label"></label>
              </div>
            </label>
          </div>


          <div class="col-12">
            <label class="mb-3">
              <div class="custom-control custom-switch">
                <label for="buckup_allow" class="bg-check-label">
                  <i class="far fa-database"></i>
                  {{ $t('backup Allow') }} :
                </label>
                <input
                  type="checkbox"
                  id="buckup_allow"
                  v-model="item.buckup_allow"
                  class="custom-control-input"
                  value="1"
                />
                <label for="buckup_allow" class="custom-control-label"></label>
              </div>
            </label>
          </div>

          <div class="form-group col-md-6 row" v-if="item.reports_show">
            <label class="col-md-4">{{ $t('Front Reports') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="item.main_reports"
                :options="reports"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose The Required Reports In The Interface')"
                label="name"
                track-by="link"
                :hide-selected="true"
              ></multiselect>
            </div>
          </div>

          <div class="form-group col-md-6 row" v-if="item.reports_show">
            <label class="col-md-4">{{ $t('Available Reports') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="item.available_reports"
                :options="allowReports"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose The Available Reports')"
                label="name"
                track-by="id"
                :hide-selected="true"
              />
            </div>
          </div>

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Exhibits In The Facade') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="item.main_elements"
                :options="elements"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose Displays In The Interface')"
                label="name"
                track-by="id"
                :hide-selected="true"
              />
            </div>
          </div>

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Main actions') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="item.main_actions"
                :options="actions"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose Actions In The Interface')"
                label="name"
                track-by="link"
                :hide-selected="true"
              />
            </div>
          </div>

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'rolesCreate' && !$parent.stopEdit"
          >
            {{ $t('Create Role') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'rolesEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit role') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import roleGroupInput from '@/elements/add/form/roleGroupInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import TabNav from '@/elements/index/tabNav.vue'
import Tab from '@/elements/index/tab.vue'

export default {
  data() {
    return {
      path: '/roles',
      selected: 'المبيعات',
      item: {
        customers_show: '',
        main_actions: []
      },
      errors: {
        name: false
      },
      reports: [
        { name: this.$t('Overview'), link: 'overview' },
        { name: this.$t('total Report'), link: 'totalReport' },

        {
          name: this.$t('Total Customer Account'),
          link: 'customersTotalAccounts'
        },
        {
          name: this.$t('Customer Account Details'),
          link: 'customersDetailsAccounts'
        },
        {
          name: this.$t('Remaining Balance For Customers'),
          link: 'customersBalance'
        },
        { name: this.$t('Customer Guide'), link: 'customersGuide' },

        {
          name: this.$t('Total Supplier Account'),
          link: 'suppliersTotalAccounts'
        },
        {
          name: this.$t('Vendor Account Details'),
          link: 'suppliersDetailsAccounts'
        },
        {
          name: this.$t('Remaining Balance For Suppliers'),
          link: 'suppliersBalance'
        },
        {
          name: this.$t('Supplier Directory'),
          link: 'suppliersGuide'
        },

        {
          name: this.$t('Partner Is Total Account'),
          link: 'partnersTotalAccounts'
        },
        {
          name: this.$t('Partner Account Details'),
          link: 'partnersDetailsAccounts'
        },
        {
          name: this.$t('The Remaining Balance Of The Partners'),
          link: 'partnersBalance'
        },
        {
          name: this.$t('A Guide For Partners'),
          link: 'partnersGuide'
        },

        {
          name: this.$t('total Project Account'),
          link: 'projectsTotalAccounts'
        },
        {
          name: this.$t('Project Account Details'),
          link: 'projectsDetailsAccounts'
        },
        { name: this.$t('Project Assets'), link: 'projectsBalance' },
        {
          name: this.$t('A General Glimpse Of The Project'),
          link: 'partnersGuide'
        },

        {
          name: this.$t('Treasury Statement'),
          link: 'safesAccounts'
        },
        { name: this.$t('Safe Balances'), link: 'safersBalance' },

        {
          name: this.$t('Inventory Of Stores'),
          link: 'warehouseInventory'
        },
        {
          name: this.$t('The Value Of Products'),
          link: 'ProductsValue'
        },

        { name: this.$t('Sales By Invoice'), link: 'salesByInvoice' },
        { name: this.$t('Sales By Product'), link: 'salesByItem' },

        {
          name: this.$t('Purchases By Invoice'),
          link: 'purchasesByBill'
        },
        {
          name: this.$t('Purchases By Product'),
          link: 'purchasesByItem'
        },

        {
          name: this.$t('Returned By Invoice'),
          link: 'salesReturnsByInvoice'
        },
        {
          name: this.$t('Returned By Product'),
          link: 'salesReturnsByItem'
        },

        {
          name: this.$t('Purchase Returns On Invoice'),
          link: 'purchasesReturnsByBill'
        },
        {
          name: this.$t('Returns Purchase By Product'),
          link: 'purchasesReturnsByItem'
        },

        {
          name: this.$t('Invoice Requests'),
          link: 'purchasesReturnsByBill'
        },
        {
          name: this.$t('Orders By Product'),
          link: 'purchasesReturnsByItem'
        },

        {
          name: this.$t('Invoice Purchase Orders'),
          link: 'purchasesOrdersByBill'
        },
        {
          name: this.$t('Purchase Orders By Product'),
          link: 'purchasesOrdersByItem'
        },

        {
          name: this.$t('Record Sales Payments'),
          link: 'paymentHistory'
        },
        {
          name: this.$t('Record Purchase Payments'),
          link: 'purchasePaymentHistory'
        },

        {
          name: this.$t('Register Sales Cheques'),
          link: 'chequesHistory'
        },
        {
          name: this.$t('CheckIn Record'),
          link: 'purchaseChequesHistory'
        },

        {
          name: this.$t('Record Sales Discounts'),
          link: 'discountsHistory'
        },
        {
          name: this.$t('Record Purchase Discounts'),
          link: 'purchaseDiscountsHistory'
        },

        {
          name: this.$t('Invoicing Projects'),
          link: 'projectsDepositsByInvoice'
        },
        {
          name: this.$t('Product Deposit Processes'),
          link: 'projectsDepositsByItem'
        },

        {
          name: this.$t('Invoice Project Withdrawals'),
          link: 'projectsWithdrawalsByBill'
        },
        {
          name: this.$t('Product Recalls'),
          link: 'projectsWithdrawalsByItem'
        },

        {
          name: this.$t('Invoice Project Transfers'),
          link: 'projectsTransfersByInvoice'
        },
        {
          name: this.$t('Project Transfers By Product'),
          link: 'projectsTransfersByItem'
        },

        { name: this.$t('Expenses List'), link: 'expenseHistory' },
        {
          name: this.$t('Expenses By Classification'),
          link: 'expenseByCategory'
        },

        {
          name: this.$t('Employee Account Statement'),
          link: 'employeeAccountStatement'
        }
      ],
      allowReports: [
        { id: '0', name: this.$t('Overview'), link: 'overview' },
        {
          id: '52',
          name: this.$t('totalReport'),
          link: 'totalReport'
        },

        {
          id: '1',
          name: this.$t('Total Customer Account'),
          link: 'customersTotalAccounts'
        },
        {
          id: '2',
          name: this.$t('Customer Account Details'),
          link: 'customersDetailsAccounts'
        },
        {
          id: '3',
          name: this.$t('Remaining Balance For Customers'),
          link: 'customersBalance'
        },
        {
          id: '4',
          name: this.$t('Customer Guide'),
          link: 'customersGuide'
        },

        {
          id: '5',
          name: this.$t('Total Supplier Account'),
          link: 'suppliersTotalAccounts'
        },
        {
          id: '6',
          name: this.$t('Vendor Account Details'),
          link: 'suppliersDetailsAccounts'
        },
        {
          id: '7',
          name: this.$t('Remaining Balance For Suppliers'),
          link: 'suppliersBalance'
        },
        {
          id: '8',
          name: this.$t('Supplier Directory'),
          link: 'suppliersGuide'
        },

        {
          id: '9',
          name: this.$t('Treasury Statement'),
          link: 'safesAccounts'
        },
        {
          id: '10',
          name: this.$t('Safe Balances'),
          link: 'safersBalance'
        },

        {
          id: '11',
          name: this.$t('Inventory Of Stores'),
          link: 'warehouseInventory'
        },
        {
          id: '12',
          name: this.$t('The Value Of Products'),
          link: 'ProductsValue'
        },

        {
          id: '13',
          name: this.$t('Sales By Invoice'),
          link: 'salesByInvoice'
        },
        {
          id: '14',
          name: this.$t('Sales By Product'),
          link: 'salesByItem'
        },

        {
          id: '15',
          name: this.$t('Purchases By Invoice'),
          link: 'purchasesByBill'
        },
        {
          id: '16',
          name: this.$t('Purchases By Product'),
          link: 'purchasesByItem'
        },

        {
          id: '17',
          name: this.$t('Returned By Invoice'),
          link: 'salesReturnsByInvoice'
        },
        {
          id: '18',
          name: this.$t('Returned By Product'),
          link: 'salesReturnsByItem'
        },

        {
          id: '19',
          name: this.$t('Purchase Returns On Invoice'),
          link: 'purchasesReturnsByBill'
        },
        {
          id: '20',
          name: this.$t('Returns Purchase By Product'),
          link: 'purchasesReturnsByItem'
        },

        {
          id: '21',
          name: this.$t('Invoice Requests'),
          link: 'purchasesReturnsByBill'
        },
        {
          id: '22',
          name: this.$t('Orders By Product'),
          link: 'purchasesReturnsByItem'
        },

        {
          id: '23',
          name: this.$t('Invoice Purchase Orders'),
          link: 'purchasesOrdersByBill'
        },
        {
          id: '24',
          name: this.$t('Purchase Orders By Product'),
          link: 'purchasesOrdersByItem'
        },

        {
          id: '25',
          name: this.$t('Record Sales Payments'),
          link: 'paymentHistory'
        },
        {
          id: '26',
          name: this.$t('Record Purchase Payments'),
          link: 'purchasePaymentHistory'
        },

        {
          id: '27',
          name: this.$t('Register Sales Cheques'),
          link: 'chequesHistory'
        },
        {
          id: '28',
          name: this.$t('Check In Record'),
          link: 'purchaseChequesHistory'
        },

        {
          id: '29',
          name: this.$t('Record Sales Discounts'),
          link: 'discountsHistory'
        },
        {
          id: '30',
          name: this.$t('Record Purchase Discounts'),
          link: 'purchaseDiscountsHistory'
        },

        {
          id: '31',
          name: this.$t('Expenses List'),
          link: 'expenseHistory'
        },
        {
          id: '32',
          name: this.$t('Expenses By Classification'),
          link: 'expenseByCategory'
        },

        {
          id: '33',
          name: this.$t('Employee Account Statement'),
          link: 'employeeAccountStatement'
        },

        {
          id: '34',
          name: this.$t('Partner Is Total Account'),
          link: 'partnersTotalAccounts'
        },
        {
          id: '35',
          name: this.$t('Partner Account Details'),
          link: 'partnersDetailsAccounts'
        },
        {
          id: '36',
          name: this.$t('The Remaining Balance Of The Partners'),
          link: 'partnersBalance'
        },
        {
          id: '37',
          name: this.$t('AGuide For Partners'),
          link: 'partnersGuide'
        },

        {
          id: '38',
          name: this.$t('total Project Account'),
          link: 'projectsTotalAccounts'
        },
        {
          id: '39',
          name: this.$t('Project Account Details'),
          link: 'projectsDetailsAccounts'
        },
        {
          id: '40',
          name: this.$t('Project Assets'),
          link: 'projectsBalance'
        },
        {
          id: '41',
          name: this.$t('A General Glimpse Of The Project'),
          link: 'partnersGuide'
        },
        {
          id: '42',
          name: this.$t('Invoicing Projects'),
          link: 'projectsDepositsByInvoice'
        },
        {
          id: '43',
          name: this.$t('Product Deposit Processes'),
          link: 'projectsDepositsByItem'
        },

        {
          id: '44',
          name: this.$t('Invoice Project Withdrawals'),
          link: 'projectsWithdrawalsByBill'
        },
        {
          id: '45',
          name: this.$t('Product Recalls'),
          link: 'projectsWithdrawalsByItem'
        },

        {
          id: '46',
          name: this.$t('Invoice Project Transfers'),
          link: 'projectsTransfersByInvoice'
        },
        {
          id: '47',
          name: this.$t('Project Transfers By Product'),
          link: 'projectsTransfersByItem'
        }
      ],
      elements: [
        { id: '1', name: this.$t('Billing Vs Payment') },
        { id: '2', name: this.$t('Profit And Loss') },
        { id: '3', name: this.$t('Store Alerts') },
        { id: '4', name: this.$t('An Overview Of The Offers') },
        { id: '5', name: this.$t('Recent Offers') },
        { id: '6', name: this.$t('Unpaid Bills') },
        { id: '7', name: this.$t('Recent Demand Bonds') },

        { id: '8', name: this.$t('Urgent Actions') },
        { id: '9', name: this.$t('Highest Expenses ByC ategory') },
        { id: '10', name: this.$t('MostActiveCustomers') },
        { id: '11', name: this.$t('Billing Overview') },
        { id: '12', name: this.$t('Recent Bills') },
        { id: '13', name: this.$t('An Overview Of The Demand Bonds') },
        { id: '14', name: this.$t('Customer Balance Alert') }
      ],
      actions: [
        {
          name: this.$t('Add customer'),
          link: '/customers/create',
          icon: 'user-alt'
        },
        {
          name: this.$t('addSupplier'),
          link: '/suppliers/create',
          icon: 'user-tie'
        },
        {
          name: this.$t('addPartner'),
          link: '/partners/create',
          icon: 'handshake'
        },
        {
          name: this.$t('addAccount'),
          link: '/accounts/create',
          icon: 'coins'
        },
        {
          name: this.$t('addProject'),
          link: '/projects/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('addSafe'),
          link: '/safes/create',
          icon: 'treasure-chest'
        },
        {
          name: this.$t('add Banck Account'),
          link: '/bankAccounts/create',
          icon: 'university'
        },
        {
          name: this.$t('add Capital'),
          link: '/capitals/create',
          icon: 'archway'
        },
        {
          name: this.$t('add Store'),
          link: '/stores/create',
          icon: 'store'
        },
        {
          name: this.$t('add Product'),
          link: '/products/create',
          icon: 'store-alt'
        },
        {
          name: this.$t('add Matrial'),
          link: '/matrials/create',
          icon: 'store-alt'
        },
        {
          name: this.$t('add Subscription'),
          link: '/subscriptions/create',
          icon: 'history'
        },
        {
          name: this.$t('add Employee'),
          link: '/employees/create',
          icon: 'user-cog'
        },
        {
          name: this.$t('addQuotation'),
          link: '/quotations/create',
          icon: 'user-cog'
        },
        {
          name: this.$t('add subscriptions Operation'),
          link: '/subscriptionsOperations/create',
          icon: 'stopwatch'
        },
        {
          name: this.$t('add Invoice'),
          link: '/invoices/create',
          icon: 'file-invoice-dollar'
        },
        {
          name: this.$t('add Bill'),
          link: '/bills/create',
          icon: 'file-invoice-dollar'
        },
        {
          name: this.$t('add SaleReturn'),
          link: '/saleReturns/create',
          icon: 'exchange'
        },
        {
          name: this.$t('add purchaseReturn'),
          link: '/purchaseReturns/create',
          icon: 'exchange'
        },
        {
          name: this.$t('add order'),
          link: '/orders/create',
          icon: 'briefcase'
        },
        {
          name: this.$t('add purchaseOrder'),
          link: '/purchaseOrders/create',
          icon: 'briefcase'
        },
        {
          name: this.$t('add SalesPayment'),
          link: '/salesPayments/create',
          icon: 'credit-card-front'
        },
        {
          name: this.$t('add PurchasePayment'),
          link: '/purchasePayments/create',
          icon: 'credit-card-front'
        },
        {
          name: this.$t('add SalesCheque'),
          link: '/salesCheques/create',
          icon: 'money-check-alt'
        },
        {
          name: this.$t('add PurchaseCheque'),
          link: '/purchaseCheques/create',
          icon: 'money-check-alt'
        },
        {
          name: this.$t('add SalesDiscount'),
          link: '/salesDiscounts/create',
          icon: 'badge-percent'
        },
        {
          name: this.$t('add PurchaseDiscount'),
          link: '/purchaseDiscounts/create',
          icon: 'badge-percent'
        },
        {
          name: this.$t('add SalesInstallment'),
          link: '/salesInstallments/create',
          icon: 'wallet'
        },
        {
          name: this.$t('add PurchaseInstallment'),
          link: '/purchaseInstallments/create',
          icon: 'wallet'
        },
        {
          name: this.$t('add Expense'),
          link: '/expenses/create',
          icon: 'usd-square'
        },
        {
          name: this.$t('add SafesDeposit'),
          link: '/safesDeposits/create',
          icon: 'envelope-open-dollar'
        },
        {
          name: this.$t('add SafesWithdrawal'),
          link: '/safesWithdrawals/create',
          icon: 'envelope-open-dollar'
        },
        {
          name: this.$t('add SafesTransfer'),
          link: '/safesTransfers/create',
          icon: 'envelope-open-dollar'
        },
        {
          name: this.$t('add StoresDeposit'),
          link: '/storesDeposits/create',
          icon: 'boxes-alt'
        },
        {
          name: this.$t('add StoresWithdrawal'),
          link: '/storesWithdrawals/create',
          icon: 'boxes-alt'
        },
        {
          name: this.$t('add StoresTransfer'),
          link: '/storesTransfers/create',
          icon: 'boxes-alt'
        },
        {
          name: this.$t('add PartnersDeposit'),
          link: '/partnersDeposits/create',
          icon: 'handshake'
        },
        {
          name: this.$t('add PartnersWithdrawal'),
          link: '/partnersWithdrawals/create',
          icon: 'handshake'
        },
        {
          name: this.$t('add PartnersSettlement'),
          link: '/partnersSettlements/create',
          icon: 'handshake'
        },
        {
          name: this.$t('add PartnersProfit'),
          link: '/partnersProfits/create',
          icon: 'handshake'
        },
        {
          name: this.$t('add AccountsDeposit'),
          link: '/accountsDeposits/create',
          icon: 'hand-holding-usd'
        },
        {
          name: this.$t('add AccountsWithdrawal'),
          link: '/accountsWithdrawals/create',
          icon: 'hand-holding-usd'
        },
        {
          name: this.$t('add ProjectsDeposit'),
          link: '/projectsDeposits/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('add ProjectsWithdrawal'),
          link: '/projectsWithdrawals/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('add ProjectsTransfer'),
          link: '/projectsTransfers/create',
          icon: 'chart-line'
        },
        {
          name: this.$t('add ManufacturingModel'),
          link: '/manufacturingModels/create',
          icon: 'hammer'
        },
        {
          name: this.$t('add ManufacturingOperation'),
          link: '/manufacturingOperations/create',
          icon: 'hammer'
        },
        {
          name: this.$t('sowPointOfSales'),
          link: '/pointOfSales',
          icon: 'cash-register'
        },
        {
          name: this.$t('add Employees Salaries'),
          link: '/employeesSalaries/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('add Employees Incentive'),
          link: '/employeesIncentives/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('add Employees Deduction'),
          link: '/employeesDeductions/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('add Employees Withdrawal'),
          link: '/employeesWithdrawals/create',
          icon: 'user-chart'
        },
        {
          name: this.$t('add Endorsement'),
          link: '/endorsements/create',
          icon: 'file-signature'
        },
        {
          name: this.$t('add Deposit'),
          link: '/deposits/create',
          icon: 'wallet'
        },
        {
          name: this.$t('add City'),
          link: '/cities/create',
          icon: 'shipping-fast'
        },
        {
          name: this.$t('add PickupLocation'),
          link: '/pickupLocations/create',
          icon: 'shipping-fast'
        },
        {
          name: this.$t('add ShippingOperation'),
          link: '/shippingOperations/create',
          icon: 'shipping-fast'
        }
      ]
    }
  },
  mounted() {
    if (this.$route.name == 'rolesEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    }
  },
  methods: {
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.$parent.stopEdit = false
      })
    },
    setSelected(tab) {
      this.selected = tab
    },
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (error == 0) {
        if (this.$route.name == 'rolesCreate') {
          this.$parent.stopEdit = true
          axios.post(this.$linkGenerator(this.path), this.item).then((response) => {
            this.$toast.success(this.$t('the Role Was Added Successfully'))
            this.$router.push(this.path)
            this.$parent.stopEdit = false
            return response
          })
        } else if (this.$route.name == 'rolesEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$toast.success(this.$t('the Role Has Been Modified Successfully'))
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$toast.error(this.$t('please Make Sure Of The Input'))
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  },
  components: {
    formInput,
    dvider,
    addHeader,
    formTextarea,
    roleGroupInput,
    loading,
    TabNav,
    Tab
  }
}
</script>
