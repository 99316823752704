import { helpers } from '@vuelidate/validators'

export const requiredIfCreate = helpers.withParams({ type: 'requiredIfCreate' }, (value) => {
  const url = window.location.href
  const segments = url.split('/')
  const route = segments[segments.length - 1]
  if (route !== 'edit') {
    return !!value
  }
  return true
})

export const requiredIfClientType = (type) => {
  return helpers.withParams({ type: 'requiredIfClientType', type }, function (value, parentVm) {
    // Check if the client_type matches the given type
    return parentVm.client_type != type || !!value
  })
}
