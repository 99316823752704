<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :icon="'archway'"
        :title="item.name"
        :stat="item.stat"
        :statText="item.stat ? $t('Active') : $t('Inactive')"
        :subData="[
          {
            title: $t('Current balance'),
            value: this.$moneyFormat(item.cost)
          }
        ]"
        :buttons="[
          {
            title: $t('Edit'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('capitals_edit')
          }
        ]"
      />
      <showCard
        class="col-12"
        :title="$t('Origin information')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'signature',
              title: $t('Original name'),
              value: item.name
            },
            {
              icon: 'minus-hexagon',
              title: $t('Depreciation rate'),
              value: item.depreciation_rate
            },
            {
              icon: 'calendar',
              title: $t('Purchase date'),
              value: item.date
            }
          ],
          scondCloum: [
            {
              icon: 'dollar-sign',
              title: $t('Value'),
              value: this.$moneyFormat(item.cost)
            },
            {
              icon: 'toggle-on',
              title: $t('Status'),
              value: item.stat != 0 ? $t('effective') : $t('Ineffective')
            },
            {
              icon: 'calendar',
              title: $t('Date of sale'),
              value: item.expiry_date
            }
          ]
        }"
        :paragraphs="[
          {
            title: $t('Description of the original'),
            value: item.descripton
          },
          { title: $t('Other detailed data'), value: item.details },
          { title: $t('Reviews'), value: item.notes }
        ]"
      />
      <div class="col-12">
        <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'
import HistoryCard from '@/components/historyCard.vue'

export default {
  data() {
    return {
      path: '/capitals',
      loaded: false,
      item: {}
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      var item = this.$database.capitals.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.loaded = true
      })
    }
  },
  components: {
    showHeader,
    showCard,
    HistoryCard
  }
}
</script>
