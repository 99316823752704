<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :icon="'store-alt'"
        :title="item.name"
        :subData="[
          { title: $t('The state'), value: item.country },
          { title: $t('City'), value: item.city }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('pickup_locations_edit')
          }
        ]"
      />
      <showCard
        class="col-12"
        :title="$t('the Address Of The Pickup Locations')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Title'),
              value: item.address1
            },
            {
              icon: 'location-arrow',
              title: $t('The entity'),
              value: item.entity
            },
            {
              icon: 'map-marked',
              title: $t('Postal code'),
              value: item.zip
            }
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Supplement'),
              value: item.address2
            },
            {
              icon: 'city',
              title: $t('City'),
              value: item.city
            },
            {
              icon: 'globe-americas',
              title: $t('The state'),
              value: item.country
            }
          ]
        }"
      />
      <showCard
        class="col-12"
        :title="$t('contact Information')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'phone',
              title: $t('Mobile number'),
              value: item.mobile
            }
          ],
          scondCloum: [
            {
              icon: 'phone',
              title: $t('another Number'),
              value: item.mobile2
            }
          ]
        }"
      />
      <div class="col-12">
        <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'
import HistoryCard from '@/components/historyCard.vue'

export default {
  data() {
    return {
      path: '/pickupLocations',
      item: {}
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      var item = this.$database.pickupLocations.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    }
  },
  components: {
    showHeader,
    showCard,
    HistoryCard
  }
}
</script>
<style></style>
