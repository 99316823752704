import { ref } from 'vue'
import Vue from 'vue'
import axios from 'axios'
import { createToastInterface } from 'vue-toastification'
import linkGenerator from '@/helpers/linkGenerator'
import initItem from '@/helpers/initItem'
import { $t } from './translate'

// form validations
import useVuelidate from '@vuelidate/core'

const toast = createToastInterface()

const methods = (data) => {
  const { path, localData, formSetup, rules, standardForm = data.standardForm ?? false } = data

  const url = window.location.href
  const segments = url.split('/')
  const route = segments[segments.length - 1]
  const id = route == 'edit' ? segments[segments.length - 2] : 0

  const loading = ref(false)
  const uploadLoading = ref(false)
  const item = ref(formSetup)

  const validate = useVuelidate(rules, item)

  const saveItem = async () => {
    if (uploadLoading.value) {
      toast.error($t('Please wait to upload files'), {
        position: 'top-center'
      })
      return {
        status: false
      }
    }

    const valid = await validate.value.$validate()

    if (!valid) {
      if (validate.value.$errors.length > 1) {
        toast.error(
          `${$t(validate.value.$errors[0].$message)} ${$t('And')} ${validate.value.$errors.length - 1} ${$t('More')}`,
          {
            position: 'top-center'
          }
        )
      } else {
        toast.error($t(validate.value.$errors[0].$message), {
          position: 'top-center'
        })
      }
      return {
        status: false
      }
    }

    loading.value = true

    if (route == 'create') {
      Vue.prototype.$addToLocalDatabase(item.value, path)
      toast.success($t('Item created successfully'), {
        position: 'top-center'
      })
      loading.value = false
      return {
        status: true
      }
    } else {
      try {
        await axios.put(linkGenerator('/' + path + (standardForm ? '' : '/' + id)), item.value)
        toast.success($t('Item updated successfully'), {
          position: 'top-center'
        })
        loading.value = false
        return {
          status: true
        }
      } catch (error) {
        handleErrors(error)
        loading.value = false
        // return to stop
        return {
          status: false
        }
      }
    }
  }

  const handleErrors = (error) => {
    loading.value = false
    if (error.code == 'ERR_NETWORK') {
      toast.error($t('Network error you have error in your network'), {
        position: 'top-center'
      })
    } else if (error.code == 'ERR_BAD_REQUEST') {
      for (const key in error.response.data.errors) {
        toast.error(error.response.data.errors[key][0], {
          position: 'top-center'
        })
      }
    }
  }

  const getItem = () => {
    loading.value = true
    if (localData) {
      let localItem = Vue.prototype.$database[path].find((el) => el.id == id)
      if (localItem) item.value = initItem(localItem)
      loading.value = false
    } else {
      axios.get(linkGenerator('/' + path + (standardForm ? '' : '/' + id))).then((response) => {
        item.value = initItem(response.data)
        loading.value = false
      })
    }
  }

  const getInputError = (paths) => {
    const filteredErrors = validate.value.$errors
      .filter((el) => el.$propertyPath == paths)
      .map((el) => el.$message)
    return filteredErrors
  }

  const updateUploadLoading = (value) => (uploadLoading.value = value)

  if (route == 'edit' || standardForm) getItem()

  return {
    item,
    loading,
    saveItem,
    getItem,
    getInputError,
    updateUploadLoading
  }
}

export default methods
