<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a>
                    <strong> {{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary d-none d-md-inline-block"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
                <a @click="print(item.id)" class="btn btn-sm btn-primary d-none d-md-inline-block">
                  <i class="fas fa-print"></i>
                </a>
                <a
                  :href="$linkGenerator('/expenses/' + item.id + '/pdf')"
                  download
                  class="btn btn-sm btn-primary d-none d-md-inline-block"
                >
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-usd-square"></i>
              {{ $t('Expense details') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <table class="table table-sm table-nowrap customed-table-small">
                  <tbody>
                    <tr>
                      <th scope="row">
                        {{ $t('Allowance code') }}
                      </th>
                      <td>{{ item.code != null ? item.code : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('Date') }}</th>
                      <td>{{ $dateFormat(item.date) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Administrator') }}
                      </th>
                      <td>{{ item.user ? item.user.name : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('Reference') }}</th>
                      <td>
                        {{ item.refrance != null ? item.refrance : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('Paid for') }}</th>
                      <td>{{ item.cash_to != null ? item.cash_to : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('Section') }}</th>
                      <td scope="row">
                        {{ item.expenseSection != null ? item.expenseSection.name : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('That about') }}</th>
                      <td>
                        {{ item.description != null ? item.description : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('Treasury') }}</th>
                      <td>
                        <router-link :to="'/safes/' + item.safe.id" v-if="item.safe">{{
                          item.safe.name
                        }}</router-link>
                        {{ item.safe ? '' : '--' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-3">
                <div class="price-show">
                  <div class="price-content">
                    <p>{{ $t('Cost') }}</p>
                    <h1>{{ $moneyFormat(item.cost) }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-user-alt"></i>
              {{ $t('Payment details') }}
            </h4>
          </div>
          <div class="card-body">
            <table class="table table-sm table-nowrap">
              <thead>
                <tr>
                  <th scope="col">{{ $t('Customer') }}</th>
                  <th scope="col">{{ $t('Supplier') }}</th>
                  <th scope="col">{{ $t('Cost') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    {{ item.customer != null ? item.customer.name : '--' }}
                  </th>
                  <th scope="row">
                    {{ item.supplier != null ? item.supplier.name : '--' }}
                  </th>
                  <td>{{ $moneyFormat(item.cost) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <showFile />
      </div>
      <div class="col-12">
        <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'
import HistoryCard from '@/components/historyCard.vue'

export default {
  data() {
    return {
      path: '/expenses',
      item: {},

      printInvoice: false
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    },
    print() {
      this.$parent.printedExpense = this.item
    }
  },
  components: {
    showFile,
    HistoryCard
  }
}
</script>
