<template>
  <div class="row container-fluid">
    <div class="col-12">
      <PageHeader
        :title="$t('stores')"
        :description="$t('From here you can control your stores reports')"
      />
    </div>
    <!-- تقارير المخازن -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('stores')"
      :icon="'fa-store-alt'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 11 || el.id == 12) || $user.admin) &&
        $site.stores_allow
      "
      :reports="[
        {
          name: $t('Inventory Of Stores'),
          link: './warehouseInventory',
          role: $user.role.available_reports.find((el) => el.id == 11) || $user.admin
        },
        {
          name: $t('The Value Of Products'),
          link: '/ProductsValue',
          role: $user.role.available_reports.find((el) => el.id == 12) || $user.admin
        },
        {
          name: $t('Store alerts'),
          link: '/productAlerts',
          role: $user.role.available_reports.find((el) => el.id == 12) || $user.admin
        },
        {
          name: $t('Validity alerts'),
          link: '/validityAlerts',
          role: $user.role.available_reports.find((el) => el.id == 12) || $user.admin
        }
      ]"
    />
    <!-- تقارير المنتجات -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('products')"
      :icon="'fa-store-alt'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 52) || $user.admin) &&
        $site.stores_allow
      "
      :reports="[
        {
          name: $t('product Account Statement'),
          link: './productDetailsAccounts',
          role: $user.role.available_reports.find((el) => el.id == 52) || $user.admin
        },
        {
          name: $t('inactive Products'),
          link: '/inactiveProducts',
          role: $user.role.available_reports.find((el) => el.id == 4) || $user.admin
        }
      ]"
    />
    <!-- تقارير ايداع المخازن  -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('Warehouse Deposit')"
      :icon="'far fa-store-alt'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 13 || el.id == 14) || $user.admin) &&
        ($site.storesDeposits_allow ||
          $site.storesWithdrawals_allow ||
          $site.storesTransfers_allow) &&
        ($user.admin ||
          $user.role.stores_deposits_show ||
          $user.role.stores_withdrawals_show ||
          $user.role.stores_transfers_show)
      "
      :reports="[
        {
          name: $t('Deposit PerInvoice'),
          link: './storesDepositsByInvoice',
          role: $user.role.available_reports.find((el) => el.id == 13) || $user.admin
        },
        {
          name: $t('Deposit Product'),
          link: '/storesDepositsByItem',
          role: $user.role.available_reports.find((el) => el.id == 14) || $user.admin
        }
      ]"
    />
    <!-- تقارير سحب المخازن  -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('Store Withdrawals')"
      :icon="'fa-file-invoice-dollar'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 13 || el.id == 14) || $user.admin) &&
        ($site.storesDeposits_allow ||
          $site.storesWithdrawals_allow ||
          $site.storesTransfers_allow) &&
        ($user.admin ||
          $user.role.stores_deposits_show ||
          $user.role.stores_withdrawals_show ||
          $user.role.stores_transfers_show)
      "
      :reports="[
        {
          name: $t('withdrawal According Invoice'),
          link: './storesWithdrawalsByBill',
          role: $user.role.available_reports.find((el) => el.id == 13) || $user.admin
        },
        {
          name: $t('Withdrawal by product'),
          link: '/storesWithdrawalsByItem',
          role: $user.role.available_reports.find((el) => el.id == 14) || $user.admin
        }
      ]"
    />
    <!-- تقارير نقل المخازن  -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('warehouse Transfer')"
      :icon="'fa-file-invoice-dollar'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 13 || el.id == 14) || $user.admin) &&
        ($site.storesDeposits_allow ||
          $site.storesWithdrawals_allow ||
          $site.storesTransfers_allow) &&
        ($user.admin ||
          $user.role.stores_deposits_show ||
          $user.role.stores_withdrawals_show ||
          $user.role.stores_transfers_show)
      "
      :reports="[
        {
          name: $t('transfer invoice'),
          link: './storesTransfersByInvoice',
          role: $user.role.available_reports.find((el) => el.id == 13) || $user.admin
        },
        {
          name: $t('transfer Product'),
          link: '/storesTransfersByItem',
          role: $user.role.available_reports.find((el) => el.id == 14) || $user.admin
        }
      ]"
    />
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import PageHeader from '@/components/pageHeader'
export default {
  components: {
    reportsCard,
    PageHeader
  }
}
</script>
