import customersIndex from '@/pages/sales/customers/index.vue'
import customersForm from '@/pages/sales/customers/form.vue'
import customersShow from '@/pages/sales/customers/show.vue'

import invoicesIndex from '@/pages/sales/invoices/index.vue'
import invoicesForm from '@/pages/sales/invoices/form.vue'
import invoicesShow from '@/pages/sales/invoices/show.vue'

import saleReturnsIndex from '@/pages/sales/saleReturns/index.vue'
import saleReturnsForm from '@/pages/sales/saleReturns/form.vue'
import saleReturnsShow from '@/pages/sales/saleReturns/show.vue'

const routes = [
  // customers routes
  {
    path: 'customers',
    name: 'customers',
    component: customersIndex
  },
  {
    path: 'customers/create',
    name: 'customersCreate',
    component: customersForm
  },
  {
    path: 'customers/:id/edit',
    name: 'customersEdit',
    component: customersForm
  },

  {
    path: 'customers/:id',
    name: 'customersShow',
    component: customersShow
  },

  //invoice routs
  {
    path: 'invoices',
    name: 'invoices',
    component: invoicesIndex
  },
  {
    path: 'invoices/create',
    name: 'invoicesCreate',
    component: invoicesForm
  },
  {
    path: 'invoices/:id/edit',
    name: 'invoicesEdit',
    component: invoicesForm
  },
  {
    path: 'invoices/:id',
    name: 'invoicesShow',
    component: invoicesShow
  },

  //saleReturns routs
  {
    path: 'saleReturns',
    name: 'saleReturns',
    component: saleReturnsIndex
  },
  {
    path: 'saleReturns/create',
    name: 'saleReturnsCreate',
    component: saleReturnsForm
  },
  {
    path: 'saleReturns/:id/edit',
    name: 'saleReturnsEdit',
    component: saleReturnsForm
  },
  {
    path: 'saleReturns/:id',
    name: 'saleReturnsShow',
    component: saleReturnsShow
  }
]

export default routes
