<template>
  <div>
    <div class="input-group input-group-flush w-100 search-input" ref="elementRef">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-search"></i></span>
      </div>
      <input
        type="search"
        :id="!options.change_customer && !options.barcode_allow ? 'searchInput' : ''"
        autocomplete="off"
        :placeholder="$t('Search for product')"
        v-model="search"
        class="form-control list-search"
        @keydown="handelKey"
        @focus="searchForProducts"
        @keyup="searchForProducts"
      />
      <ul
        class="list-unstyled search-selects"
        style="top: 43px"
        v-if="searchProducts.length > 0 && showItemsList"
      >
        <li
          v-for="product in searchProducts"
          :key="product.id"
          @click="selectProduct(product.id)"
          :class="{ focused: product.focused }"
        >
          {{ product.name }}
          <!-- If have limit quantity-->
          <span v-if="product.track_quantity == 1" class="quotation-item-quantity">
            {{ product.max_quantity }}
          </span>
          <!-- else -->
          <span class="quotation-item-quantity" v-else>
            <i class="fal fa-infinity"></i>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import Vue from 'vue'
import { defineProps, ref, watch } from 'vue'
import { createToastInterface } from 'vue-toastification'
import { useClickOutside } from '@/helpers/useClickOutside'
import { $t } from '@/helpers/translate'

// Click outside logic
const { elementRef, isOutside } = useClickOutside()
watch(isOutside, (value, oldValue) => {
  if (value && oldValue != value) removeItemList()
})

const toast = createToastInterface()

const search = ref('')
const searchProducts = ref([])
const showItemsList = ref(false)

const searchForProducts = () => {
  // SearchRGX
  let searchRGX = new RegExp(search.value, 'i')
  // get search Items
  let products = props.filteredProducts
    .sort(Vue.prototype.$dynamicSort({ orderBy: 'name', orderType: 'desc' }))
    .filter((el) => searchRGX.test(el.name))
    .slice(0, 15)
  // Show products list
  if (products.length) {
    searchProducts.value = products
    showItemsList.value = true
  } else {
    searchProducts.value = []
  }
}
const handelKey = (e) => {
  // Move up and down in items
  if (
    (e.key == 'ArrowDown' && searchProducts.value.length != 0) ||
    (e.key == 'ArrowUp' && searchProducts.value.length != 0)
  ) {
    let product = searchProducts.value.find((el) => el.focused == true)
    let selected = searchProducts.value.indexOf(product)
    let length = searchProducts.value.length

    if (selected > -1) Vue.prototype.$set(searchProducts.value[selected], 'focused', false)

    if (e.key == 'ArrowDown') {
      if (selected < length - 1) {
        Vue.prototype.$set(searchProducts.value[selected + 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchProducts.value[0], 'focused', true)
      }
    } else if (e.key == 'ArrowUp') {
      if (selected > 0) {
        Vue.prototype.$set(searchProducts.value[selected - 1], 'focused', true)
      } else {
        Vue.prototype.$set(searchProducts.value[length - 1], 'focused', true)
      }
    }
  }
  // Select product
  else if (e.key == 'Enter') {
    let item = props.filteredProducts.find((el) => el.barcode == search.value)
    if (item) {
      selectProduct(item.id)
    } else {
      let product = searchProducts.value.find((el) => el.focused == true)
      if (product) selectProduct(product.id)
      else
        toast.error($t('Product is not available in stoke'), {
          position: 'top-center'
        })
    }
  }
}

const selectProduct = (id) => {
  search.value = ''
  emits('addProduct', id)
  removeItemList()
}

const removeItemList = () => (showItemsList.value = false)

const props = defineProps(['options', 'filteredProducts'])
const emits = defineEmits(['addProduct'])
</script>
