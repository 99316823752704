<template>
  <div class="print-model">
    <div id="printMe" ref="printMe">
      <StoresWithdrawalComponent :item="item" :loading="loading" />
      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in options.stores_withdrawals_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
      <!-- <p class="pioneer-data">تم الإنشاء بواسطة ثاني أثنين</p> -->
    </div>
  </div>
</template>
<script>
import StoresWithdrawalComponent from './components/storesWithdrawal.vue'
export default {
  data() {
    return {
      path: '/invoices',
      logo: localStorage.getItem('logo'),
      item: {},
      loading: true,
      printType: true,
      itemsCount: 0
    }
  },
  methods: {
    getitem() {
      this.loading = true
      this.item = this.$parent.printedStoresWithdrawal
      this.item.quotationItems.forEach((item) => {
        this.itemsCount += item.quantity - 0
      })
      this.loading = false
      var myel = this
      setTimeout(function () {
        var text = document.getElementById('printMe').innerHTML
        document.getElementById('printMe').remove()
        var myframe = document.getElementById('inviceIframe')
        myframe.contentDocument.body.innerHTML = text
        setTimeout(function () {
          myframe.focus()
          myframe.contentWindow.print()
          myel.colse()
        }, 100)
      }, 20)
    },
    colse() {
      this.$parent.printedStoresWithdrawal = false
    }
  },
  mounted() {
    this.getitem()
  },
  props: ['options'],
  components: {
    StoresWithdrawalComponent
  }
}
</script>
