var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'user-cog',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        { title: _vm.$t('Title'), value: _vm.item.job_title },
        {
          title: _vm.$t('Salary'),
          value: _vm.$moneyFormat(_vm.item.salary + ' ' + _vm.$option.currency)
        }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('empolyees_edit')
        }
      ]}}),_c('div',{staticClass:"col-12"},[_c('showFile')],1),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c('HistoryCard',{attrs:{"items":_vm.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }