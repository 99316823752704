var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'chart-line',"title":_vm.item.code,"buttons":[
        {
          title: _vm.$t('Edit'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('manufacturing_operations_edit')
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Basic information'),"cloumns":{
        firstCloum: [
          {
            icon: 'user',
            title: _vm.$t('Manufacturing code'),
            value: _vm.item.code
          },
          {
            icon: 'calendar',
            title: _vm.$t('Start date'),
            value: _vm.$dateFormat(_vm.item.start_date)
          },
          {
            icon: 'money-bill',
            title: _vm.$t('Expected cost'),
            value: _vm.item.expected_cost + ' ' + _vm.$option.currency
          },
          {
            icon: 'money-bill',
            title: _vm.$t('Cost'),
            value: _vm.item.cost + ' ' + _vm.$option.currency
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('earning'),
            value: _vm.item.price - _vm.item.cost + ' ' + _vm.$option.currency
          }
        ],
        scondCloum: [
          {
            icon: 'toggle-on',
            title: _vm.$t('ProjectStatus'),
            value: _vm.item.stat == 1 ? _vm.$t('effective') : _vm.$t('Ineffective')
          },
          {
            icon: 'calendar',
            title: _vm.$t('End date'),
            value: _vm.$dateFormat(_vm.item.end_date)
          },
          {
            icon: 'money-bill-wave',
            title: _vm.$t('Expected price'),
            value: _vm.item.expected_price + ' ' + _vm.$option.currency
          },
          {
            icon: 'money-bill',
            title: _vm.$t('Price'),
            value: _vm.item.price + ' ' + _vm.$option.currency
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Earning percentage'),
            value: (((_vm.item.price - _vm.item.cost) / _vm.item.price) * 100).toFixed(1) + ' %'
          }
        ]
      }}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c('HistoryCard',{attrs:{"items":_vm.item.histories}}):_vm._e()],1)],1),_c('showFile')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }