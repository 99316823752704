<template>
  <div class="invoice-container">
    <img :src="logo" class="logo" v-if="$option.image != null" />
    <div class="title">{{ $t('Simple tax invoice') }}</div>
    <div class="invoice-number">{{ $t('Invoice number') }}: {{ item.code }}</div>
    <div class="store-name">{{ $option.name }}</div>
    <div class="store-address">{{ $option.zatca.registered_address ?? $option.address1 }}</div>

    <div class="date-info">{{ $t('Date') }}: {{ $dateFormat(item.date) }}</div>
    <div class="register-number-info">
      {{ $t('Register number') }}: {{ $option.zatca.vat_number ?? $option.tax_number }}
    </div>

    <table class="details-table">
      <thead>
        <tr>
          <th>{{ $t('Product') }}</th>
          <th>{{ $t('Quantity') }}</th>
          <th>{{ $t('Price before tax') }}</th>
          <th>{{ $t('Tax') }}</th>
          <th>{{ $t('Total') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(qItem, index) in item.quotationItems" :key="index">
          <td>{{ qItem.product_name }}</td>
          <td>{{ parseFloat(qItem.quantity).toFixed(2) }}</td>
          <td>{{ $moneyFormat(qItem.price) }}</td>
          <td>{{ $moneyFormat(qItem.average) }}</td>
          <td>{{ $moneyFormat(qItem.total) }}</td>
        </tr>
      </tbody>
    </table>

    <div class="summary">
      <div>
        {{ $t('Total amount before Taxes') }}: <span>{{ $moneyFormat(item.subtotal) }}</span>
      </div>
      <div>
        {{ $t('Taxes') }} ({{ item.tax_rate ?? 0 }}%): <span>{{ $moneyFormat(item.tax) }}</span>
      </div>
      <div class="total">
        {{ $t('Total after Taxes') }} ({{ item.tax_rate ?? 0 }}%):
        <span>{{ $moneyFormat(item.total) }}</span>
      </div>
    </div>

    <div class="footer">>>>>>>>>>> {{ $t('Close invoice') }} 0100 <<<<<<<<<<</div>

    <div class="qr-code" v-if="$option.tax_invoice">
      <QrCodeVue
        :value="
          qrCodeGenerator(
            $fullDateFormat(item.date),
            parseFloat(item.total).toFixed(2),
            parseFloat(item.tax).toFixed(2)
          )
        "
        size="130"
        level="H"
        render-as="svg"
      />
    </div>
  </div>
</template>
<script setup>
import Vue from 'vue'
import { ref, defineProps } from 'vue'
import qrCodeGenerator from '@/helpers/qrCodeGenerator'
import QrCodeVue from 'qrcode.vue'

const logo = localStorage.getItem('logo')

// Get options
const options = ref({})
const pointOfSale = Vue.prototype.$database.pointOfSales.find(
  (el) => el.id == props.item.point_of_sale_id
)
if (pointOfSale) options.value = pointOfSale

const props = defineProps(['item'])
</script>
<style scoped>
body {
  -webkit-print-color-adjust: exact !important;
}
@page {
  size: 2.8in 11in;
  margin-top: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
body {
  font-family: 'Cerebri Sans', Tajawal, sans-serif;
  background-color: white;
  direction: rtl;
  text-align: right;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
}
.invoice-container {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  text-align: center;
}
.logo {
  width: 4cm;
  margin: 4px auto;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}
.invoice-number {
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 12px;
}
.store-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  color: #333;
}
.store-address {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #333;
}
.date-info {
  text-align: start;
  font-size: 11px;
  font-weight: 500;
  color: #333;
}
.register-number-info {
  text-align: start;
  font-size: 11px;
  font-weight: 500;
  color: #333;
}
.details-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  border-bottom: 1px dashed #ddd;
}
.details-table th,
.details-table td {
  padding: 8px 3px;
  font-size: 9px;
}
.details-table th {
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;
  font-weight: bold;
}
.summary {
  text-align: right;
  margin-top: 10px;
  font-size: 12px;
}
.summary div {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
}
.summary .total {
  font-weight: bold;
  color: #333;
}
.footer {
  margin-top: 30px;
  font-size: 10px;
  color: #666;
}
.qr-code {
  margin-top: 10px;
}
</style>
