<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a
                    >{{ $t('Sales payment') }} <strong> #{{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('Modification')"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
                <a @click="print(item.id)" class="btn btn-sm btn-primary" :title="$t('Printing')">
                  <i class="fas fa-print"></i>
                </a>
                <a
                  :href="$linkGenerator('/salesCheques/' + item.id + '/pdf')"
                  download
                  class="btn btn-sm btn-primary"
                  :title="$t('Download pdf')"
                >
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-credit-card-front"></i>
              {{ $t('Payment details') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-9">
                <table class="table table-sm table-nowrap customed-table-small">
                  <tbody>
                    <tr>
                      <th scope="row">
                        {{ $t('Payment number') }}
                      </th>
                      <td>{{ item.code }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Registration date') }}
                      </th>
                      <td>{{ $dateFormat(item.created_date) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Payment date') }}
                      </th>
                      <td>{{ $dateFormat(item.date) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Administrator') }}
                      </th>
                      <td>{{ item.user ? item.user.name : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Reference number') }}
                      </th>
                      <td>
                        {{ item.refrance != null ? item.refrance : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Receive from') }}
                      </th>
                      <td>
                        <router-link
                          :to="'/customers/' + item.customer.id"
                          v-if="item.client_type == 0"
                          >{{ item.customer.name }}</router-link
                        >
                        <router-link
                          :to="'/suppliers/' + item.supplier.id"
                          v-if="item.client_type == 1"
                          >{{ item.supplier.name }}</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Payment method') }}
                      </th>
                      <td scope="row">
                        {{ item.paymentMethod != null ? item.paymentMethod.name : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('Active') }}</th>
                      <td scope="row">
                        {{ item.stat ? $t('Paid') : $t('Waiting') }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Treasury') }}
                      </th>
                      <td>
                        <router-link :to="'/safes/' + item.safe.id" v-if="item.safe">{{
                          item.safe.name
                        }}</router-link>
                        {{ item.safe ? '' : '--' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-xl-3">
                <div class="price-show">
                  <div class="price-content">
                    <p>{{ $t('Received amount') }}</p>
                    <h1>{{ $moneyFormat(item.cost) }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-user-alt"></i>
              {{ $t('Pay from the') }}
            </h4>
          </div>
          <div class="data-table-header card-body">
            <div class="row">
              <div class="col-xl-2">
                <div>
                  <div class="row">
                    <div class="col-xl-12 col-6">
                      {{ $t('Customer name') }}
                    </div>
                    <div class="col-xl-12 col-6">
                      <router-link
                        :to="'/customers/' + item.customer.id"
                        v-if="item.client_type == 0"
                        >{{ item.customer.name }}</router-link
                      >
                      <router-link
                        :to="'/suppliers/' + item.supplier.id"
                        v-if="item.client_type == 1"
                        >{{ item.supplier.name }}</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('Cash') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.cost) }}
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('Bank sees') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.bank_fees) }}
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('Bank tax') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.bank_tax_amount) }}
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('Previous balance') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.old_balance) }}
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('Total balance') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.new_balance) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <showFile />
      </div> -->
      <div class="col-12 col-md-8">
        <div class="card card-body p-5 invoice-show">
          <SalesCheques :item="item" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">شيك بيع رقم #{{ item.code }}</h4>
          </div>
          <div class="card-body">
            <table class="table table-sm table-nowrap customed-table-small">
              <tbody>
                <tr>
                  <th scope="row">
                    {{ $t('PaymentNumber') }}
                  </th>
                  <td>{{ item.code }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ $t('RegistrationDate') }}
                  </th>
                  <td>{{ $dateFormat(item.created_date) }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ $t('PaymentDate') }}
                  </th>
                  <td>{{ $dateFormat(item.date) }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ $t('Administrator') }}
                  </th>
                  <td>{{ item.user ? item.user.name : '--' }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ $t('referenceNumber') }}
                  </th>
                  <td>
                    {{ item.refrance != null ? item.refrance : '--' }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ $t('ReceiveFrom') }}
                  </th>
                  <td>
                    <router-link
                      :to="'/customers/' + item.customer.id"
                      v-if="item.client_type == 0"
                      >{{ item.customer.name }}</router-link
                    >
                    <router-link
                      :to="'/suppliers/' + item.supplier.id"
                      v-if="item.client_type == 1"
                      >{{ item.supplier.name }}</router-link
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ $t('PaymentMethod') }}
                  </th>
                  <td scope="row">
                    {{ item.paymentMethod != null ? item.paymentMethod.name : '--' }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('active') }}</th>
                  <td scope="row">
                    {{ item.stat ? $t('paid') : $t('Waiting') }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ $t('Treasury') }}
                  </th>
                  <td>
                    <router-link :to="'/safes/' + item.safe.id" v-if="item.safe">{{
                      item.safe.name
                    }}</router-link>
                    {{ item.safe ? '' : '--' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <showFile />
      </div>
      <div class="col-12">
        <HistoryCard :items="item.histories" v-if="$checkRole('history_show')" />
      </div>
    </div>
    <print v-if="printInvoice" :options="$option"></print>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'
import SalesCheques from '@/print/components/salesCheques.vue'
import print from '@/print/salesPayment.vue'
import HistoryCard from '@/components/historyCard.vue'

export default {
  data() {
    return {
      path: '/salesCheques',
      item: {},
      printInvoice: false
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    },
    print(id) {
      this.printInvoice = id
    }
  },
  components: {
    print,
    showFile,
    SalesCheques,
    HistoryCard
  }
}
</script>
<style></style>
