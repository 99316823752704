var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'store-alt',"title":_vm.item.name,"subData":[
        { title: _vm.$t('The state'), value: _vm.item.country },
        { title: _vm.$t('City'), value: _vm.item.city }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('stores_edit')
        },
        {
          title: _vm.$t('Inventory of the store'),
          icon: 'file-chart-pie',
          link: '/warehouseInventory?store_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('reports_show')
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Store address'),"cloumns":{
        firstCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('Address'),
            value: _vm.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('The side'),
            value: _vm.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('Zip code'),
            value: _vm.item.zip
          }
        ],
        scondCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('The sequel'),
            value: _vm.item.address2
          },
          { icon: 'city', title: _vm.$t('City'), value: _vm.item.city },
          {
            icon: 'globe-americas',
            title: _vm.$t('State'),
            value: _vm.item.country
          }
        ]
      }}}),_c('div',{staticClass:"col-12"},[(_vm.$checkRole('history_show'))?_c('HistoryCard',{attrs:{"items":_vm.item.histories}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }